import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ReactiveFormsModule,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ComponentFormData } from '../component-form-data';
import { QuillModule } from 'ngx-quill';
import { TranslocoModule } from '@ngneat/transloco';

export interface FormattedTextData extends ComponentFormData {
    title: string;
    text: string;
}

@Component({
    selector: 'app-formatted-text-form',
    templateUrl: './formatted-text-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        QuillModule,
        TranslocoModule,
    ],
})
export class FormattedTextFormComponent implements OnInit {
    @Input() set data(value: FormattedTextData) {
        this._data = value;
        if (this.form) {
            this.form.patchValue(value);
        }
    }
    get data(): FormattedTextData {
        return this._data;
    }
    private _data: FormattedTextData = {
        title: '',
        text: '',
    };

    @Output() dataChange = new EventEmitter<FormattedTextData>();
    @Output() cancel = new EventEmitter<void>();

    form!: FormGroup;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: [
                this.data.title,
                [Validators.required, Validators.minLength(1)],
            ],
            text: [
                this.data.text,
                [Validators.required, Validators.minLength(1)],
            ],
        });
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.dataChange.emit(this.form.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
        ],
    };
}
