<form
    class="bg-white shadow-md rounded-lg p-4"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.video-form.title"
                | transloco
        }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.video-form.description"
                | transloco
        }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.video-form.video-type"
                | transloco
        }}</mat-label>
        <mat-select formControlName="videoType" required>
            <mat-option value="youtube">YouTube</mat-option>
            <mat-option value="vimeo">Vimeo</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.video-form.video-url-id"
                | transloco
        }}</mat-label>
        <input matInput formControlName="videoId" type="text" required />
    </mat-form-field>
    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="form.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
