import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    FormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ComponentFormData } from '../component-form-data';
import { Event } from 'app/modules/aboutme/aboutme-events/aboutme-events.component';
import { TranslocoModule } from '@ngneat/transloco';

export interface EventsData extends ComponentFormData {
    title: string;
    events: Event[];
}

@Component({
    selector: 'events-form',
    templateUrl: './events-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class EventsFormComponent {
    eventForm: FormGroup;

    @Input() data: EventsData;
    @Output() dataChange = new EventEmitter<EventsData>();
    @Output() cancel = new EventEmitter<void>();

    constructor(private fb: FormBuilder) {
        this.eventForm = this.fb.group({
            title: ['', Validators.required],
            events: this.fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            this.eventForm.patchValue({
                title: this.data.title,
            });
            if (this.data.events) {
                this.data.events.forEach((event, index) => {
                    this.events.push(
                        this.fb.group({
                            title: [event.title, Validators.required],
                            date: [event.date, Validators.required],
                            location: [event.location, Validators.required],
                            link: [event.link],
                            position: [index],
                        }),
                    );
                });
            }
        }
    }

    get events(): FormArray {
        return this.eventForm.get('events') as FormArray;
    }

    addEvent() {
        const eventGroup = this.fb.group({
            title: ['', Validators.required],
            date: ['', Validators.required],
            location: ['', Validators.required],
            link: [''],
            position: [this.events.length],
        });
        this.events.push(eventGroup);
    }

    removeEvent(index: number) {
        this.events.removeAt(index);
    }

    onSubmit() {
        if (this.eventForm.valid) {
            this.dataChange.emit(this.eventForm.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
