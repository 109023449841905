<form [formGroup]="imageGridForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "title-section" | transloco }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>
    <div
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        class="drag-drop-area p-8 border-[3px] border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center"
    >
        <mat-icon class="text-gray-400">cloud_upload</mat-icon>
        <p class="text-gray-400">
            {{
                "dashboard.aboutme-editor.component-forms.image-grid.drag-and-drop-images-here"
                    | transloco
            }}
        </p>
        <input
            type="file"
            accept="image/*"
            (change)="onFileSelected($event)"
            hidden
            #fileInput
        />
        <button (click)="fileInput.click()">
            {{
                "dashboard.aboutme-editor.component-forms.image-grid.upload-images"
                    | transloco
            }}
        </button>
    </div>
    <app-remaining-space></app-remaining-space>
    <div formArrayName="images">
        <div
            *ngFor="let image of images.controls; let i = index"
            [formGroupName]="i"
            class="bg-white shadow-md rounded-lg p-4 mb-4"
        >
            <img
                [src]="imageBaseUrl + image.value.fileKey"
                alt="Image preview"
                class="image-preview"
            />
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{ "caption" | transloco }}</mat-label>
                <input matInput formControlName="caption" type="text" />
            </mat-form-field>
            <button
                mat-icon-button
                (click)="removeImage(i)"
                class="ml-2 text-grey-500"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="imageGridForm.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
