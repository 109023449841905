<form
    class="mt-4 bg-white rounded-lg shadow-md p-4"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "title" | transloco }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "description" | transloco }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.audio.audio-type"
                | transloco
        }}</mat-label>
        <mat-select formControlName="audioType">
            <mat-option *ngFor="let type of audioTypes" [value]="type">
                {{ type | titlecase }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="
                form.get('audioType')?.invalid &&
                (form.get('audioType')?.dirty || form.get('audioType')?.touched)
            "
        >
            {{
                "dashboard.aboutme-editor.component-forms.audio.audio-type-required"
                    | transloco
            }}
        </mat-error>
    </mat-form-field>

    <div class="p-2">
        <p class="font-bold" *ngIf="form.get('audioType')?.value">
            {{
                "dashboard.aboutme-editor.component-forms.audio.how-to-get-track-id"
                    | transloco
            }}
        </p>
        <p *ngIf="form.get('audioType')?.value === 'spotify'">
            {{
                "dashboard.aboutme-editor.component-forms.audio.spotify-instructions"
                    | transloco
            }}
        </p>
        <p *ngIf="form.get('audioType')?.value === 'soundcloud'">
            {{
                "dashboard.aboutme-editor.component-forms.audio.soundcloud-instructions"
                    | transloco
            }}
        </p>
    </div>

    <mat-form-field class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.audio.audio-track-url-id"
                | transloco
        }}</mat-label>
        <input
            matInput
            formControlName="trackId"
            placeholder="Track ID or Embed Code"
        />
        <mat-error
            *ngIf="
                form.get('trackId')?.hasError('invalidTrackId') &&
                (form.get('trackId')?.dirty || form.get('trackId')?.touched)
            "
        >
            {{
                "dashboard.aboutme-editor.component-forms.audio.please-enter-a-valid-spotify-track-url-or-track-id"
                    | transloco
            }}
        </mat-error>
        <mat-error
            *ngIf="
                form.get('trackId')?.hasError('invalidEmbedCode') &&
                (form.get('trackId')?.dirty || form.get('trackId')?.touched)
            "
        >
            {{
                "dashboard.aboutme-editor.component-forms.audio.please-enter-a-valid-soundcloud-embed-code"
                    | transloco
            }}
        </mat-error>
        <mat-error
            *ngIf="
                form.get('trackId')?.hasError('required') &&
                (form.get('trackId')?.dirty || form.get('trackId')?.touched)
            "
        >
            {{
                "dashboard.aboutme-editor.component-forms.audio.track-id-required"
                    | transloco
            }}
        </mat-error>
    </mat-form-field>

    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="form.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
