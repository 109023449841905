import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
    ReactiveFormsModule,
    FormBuilder,
    FormGroup,
    Validators,
    FormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { VideoType } from 'app/modules/aboutme/aboutme-video/aboutme-video.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ComponentFormData } from '../component-form-data';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

export interface VideoFormData extends ComponentFormData {
    videoId: string;
    videoType: VideoType;
    title: string;
    description: string;
}

@Component({
    selector: 'app-video-form',
    templateUrl: './video-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSnackBarModule,
        TranslocoModule,
    ],
})
export class VideoFormComponent implements OnInit {
    @Input() set data(value: VideoFormData) {
        this._data = value;
        if (this.form) {
            this.form.patchValue(value);
        }
    }
    get data(): VideoFormData {
        return this._data;
    }
    private _data: VideoFormData = {
        videoId: '',
        videoType: 'youtube',
        title: '',
        description: '',
    };

    @Output() dataChange = new EventEmitter<VideoFormData>();
    @Output() cancel = new EventEmitter<void>();

    form!: FormGroup;
    videoTypes: VideoType[] = ['youtube', 'vimeo'];

    constructor(
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService,
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            videoId: [
                this.data.videoId,
                [Validators.required, Validators.minLength(1)],
            ],
            videoType: [this.data.videoType, [Validators.required]],
            title: [this.data.title, [Validators.required]],
            description: [this.data.description],
        });
    }

    onSubmit(): void {
        if (this.form.valid) {
            const videoType = this.form.get('videoType')?.value;
            let videoId = this.form.get('videoId')?.value;
            let extractedVideoId: string | null = null;

            if (videoType === 'youtube') {
                extractedVideoId = this.extractYouTubeVideoId(videoId);
            } else if (videoType === 'vimeo') {
                extractedVideoId = this.extractVimeoVideoId(videoId);
            }

            // If a valid video ID is extracted, use it; otherwise, assume the input is already a video ID
            if (extractedVideoId) {
                videoId = extractedVideoId;
            }

            this.form.patchValue({ videoId });
            this.dataChange.emit(this.form.value);
        } else {
            this.snackBar.open(
                this.translocoService.translate(
                    'dashboard.aboutme-editor.component-forms.video-form.no-valid-video-url-or-id',
                ),
                'Close',
                {
                    duration: 3000,
                },
            );
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    private extractYouTubeVideoId(url: string): string | null {
        const regex =
            /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|e|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    private extractVimeoVideoId(url: string): string | null {
        const regex =
            /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/[\w]+\/|groups\/[\w]+\/videos\/|album\/\d+\/video\/|video\/|)(\d+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }
}
