<!-- Start of Selection -->
<form
    class="mt-4 bg-white rounded-lg shadow-md p-4"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field class="w-full">
        <input
            matInput
            formControlName="title"
            placeholder="{{ 'title' | transloco }}"
        />
        <mat-error
            *ngIf="
                form.get('title')?.invalid &&
                (form.get('title')?.dirty || form.get('title')?.touched)
            "
        >
            {{ "title" | transloco }} {{ "is-required" | transloco }}
        </mat-error>
    </mat-form-field>

    <quill-editor
        placeholder="{{ 'content' | transloco }}"
        formControlName="text"
    ></quill-editor>
    <mat-error
        *ngIf="
            form.get('text')?.invalid &&
            (form.get('text')?.dirty || form.get('text')?.touched)
        "
    >
        {{ "content" | transloco }} {{ "is-required" | transloco }}
    </mat-error>

    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="form.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
<!-- End of Selection -->
