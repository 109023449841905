import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AboutmeGuard } from './core/aboutme/guards/AboutmeGuard';
import { environment } from 'environments/environment';

const aboutmeRoute: Route = {
    path: '',
    canActivate: [AboutmeGuard],
    component: LayoutComponent,
    data: {
        layout: 'empty',
    },
    loadChildren: () =>
        import('app/modules/aboutme/aboutme.module').then(
            (m) => m.AboutmeModule,
        ),
};

const mainRoutes: Route[] = [
    // Public route
    {
        path: '',
        loadChildren: () =>
            import('app/modules/public/public.module').then(
                (m) => m.PublicModule,
            ),
    },

    // Redirect signed-in user to the '/example'
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password/:recoverycode',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule,
                    ),
            },
            {
                path: 'sign-up',
                canMatch: [NoAuthGuard],
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule,
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                canMatch: [AuthGuard],
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule,
                    ),
            },
            {
                path: 'unlock-session',
                canMatch: [AuthGuard],
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Logged in routes
    {
        path: 'dashboard',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/dashboard/dashboard.routes').then(
                (m) => m.default,
            ),
    },
    {
        path: 'dashboard/:panel',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/dashboard/dashboard.routes').then(
                (m) => m.default,
            ),
    },
    {
        path: 'aboutme-editor/:aboutmeId',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        loadChildren: () =>
            import('app/modules/aboutme-editor/aboutme-editor.module').then(
                (m) => m.default,
            ),
    },

    {
        path: '404-not-found',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        pathMatch: 'full',
        loadChildren: () =>
            import('app/modules/common/error404/error404.module').then(
                (m) => m.Error404Module,
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];

export const appRoutes: Route[] = getSubdomain() ? [aboutmeRoute] : mainRoutes;

export function getDomain(): string {
    if (!environment.production) {
        return 'emailsimple.info';
    }

    const url = new URL(window.location.href);
    const host = url.hostname;
    const parts = host.split('.');

    if (parts.length > 2) {
        return parts.slice(-2).join('.');
    }

    return host;
}

export function getSubdomain(): string | null {
    const url = new URL(window.location.href);
    const host = url.hostname;
    const parts = host.split('.');

    let numberOfDots = 2;
    if (!environment.production) {
        numberOfDots = 1;
    }
    if (parts.length > numberOfDots) {
        return parts.slice(0, parts.length - numberOfDots).join('.');
    }

    return null;
}
