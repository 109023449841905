import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SubdomainService } from '../subdomain.service';
import { environment } from 'environments/environment';
import { AboutMeService } from 'app/modules/aboutme-editor/aboutme.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AboutmeGuard implements CanActivate {
    constructor(
        private subdomainService: SubdomainService,
        private aboutmeService: AboutMeService,
    ) {}

    canActivate(): Observable<boolean> {
        const domain = this.subdomainService.getDomain();
        const subdomain = this.subdomainService.getSubdomain();

        return this.aboutmeService
            .getAboutMeBySubdomain(domain, subdomain)
            .pipe(
                map((aboutMe) => {
                    if (aboutMe) {
                        // If the aboutme is created in DB
                        return true;
                    } else {
                        // Redirect to the main page
                        window.location.href = environment.frontendUrl;
                        return false;
                    }
                }),
                catchError(() => {
                    // Handle error and redirect
                    window.location.href = environment.frontendUrl;
                    return of(false);
                }),
            );
    }
}
