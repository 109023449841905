import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ReactiveFormsModule,
    FormBuilder,
    FormGroup,
    Validators,
    FormsModule,
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ComponentFormData } from '../component-form-data';
import { AudioType } from '../../../aboutme/aboutme-audio/aboutme-audio.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

export interface AudioFormData extends ComponentFormData {
    title: string;
    description: string;
    trackId: string;
    audioType: AudioType;
}

@Component({
    selector: 'app-audio-form',
    templateUrl: './audio-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoModule,
    ],
})
export class AudioFormComponent implements OnInit {
    @Input() set data(value: AudioFormData) {
        this._data = value;
        if (this.form) {
            this.form.patchValue(value);
        }
    }
    get data(): AudioFormData {
        return this._data;
    }
    private _data: AudioFormData = {
        title: '',
        description: '',
        trackId: '',
        audioType: 'soundcloud',
    };

    @Output() dataChange = new EventEmitter<AudioFormData>();
    @Output() cancel = new EventEmitter<void>();

    form!: FormGroup;
    audioTypes: AudioType[] = ['soundcloud', 'spotify'];

    constructor(private fb: FormBuilder) {}

    private static spotifyTrackIdValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const isValidTrackId = /^[a-zA-Z0-9]{22}$/.test(value);
            const isValidUrl =
                /(?:https?:\/\/open\.spotify\.com\/(?:intl-[a-z]{2}\/)?track\/|spotify:track:)[a-zA-Z0-9]{22}/.test(
                    value,
                );
            return isValidTrackId || isValidUrl
                ? null
                : { invalidTrackId: true };
        };
    }

    private static soundCloudEmbedValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const isValidEmbedCode =
                /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/\d+/.test(
                    value,
                );
            return isValidEmbedCode ? null : { invalidEmbedCode: true };
        };
    }

    ngOnInit() {
        this.form = this.fb.group({
            title: [this.data.title, [Validators.required]],
            description: [this.data.description],
            trackId: [
                this.data.trackId,
                [Validators.required, Validators.minLength(1)],
            ],
            audioType: [this.data.audioType, [Validators.required]],
        });

        this.form.get('audioType')?.valueChanges.subscribe((audioType) => {
            this.updateTrackIdValidators(audioType);
        });

        this.updateTrackIdValidators(this.data.audioType);
    }

    private updateTrackIdValidators(audioType: AudioType): void {
        const trackIdControl = this.form.get('trackId');
        if (audioType === 'spotify') {
            trackIdControl?.setValidators([
                Validators.required,
                Validators.minLength(1),
                AudioFormComponent.spotifyTrackIdValidator(),
            ]);
        } else if (audioType === 'soundcloud') {
            trackIdControl?.setValidators([
                Validators.required,
                Validators.minLength(1),
                AudioFormComponent.soundCloudEmbedValidator(),
            ]);
        }
        trackIdControl?.updateValueAndValidity();
    }

    private extractSpotifyTrackId(url: string): string | null {
        const regex =
            /(?:https?:\/\/open\.spotify\.com\/(?:intl-[a-z]{2}\/)?track\/|spotify:track:)([a-zA-Z0-9]{22})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    private extractSoundCloudTrackId(embedCode: string): string | null {
        const regex =
            /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/(\d+)/;
        const match = embedCode.match(regex);
        return match ? match[1] : null;
    }

    onSubmit(): void {
        if (this.form.valid) {
            const audioType = this.form.get('audioType')?.value;
            let trackId = this.form.get('trackId')?.value;

            if (audioType === 'spotify') {
                const extractedId = this.extractSpotifyTrackId(trackId);
                if (extractedId) {
                    trackId = extractedId;
                }
            } else if (audioType === 'soundcloud') {
                const extractedId = this.extractSoundCloudTrackId(trackId);
                if (extractedId) {
                    trackId = extractedId;
                } else {
                    console.error('Invalid SoundCloud embed code');
                    return;
                }
            }

            this.emitDataChange(trackId);
        }
    }

    private emitDataChange(trackId: string): void {
        this.dataChange.emit({
            ...this.form.value,
            trackId: trackId,
        });
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
