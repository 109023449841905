import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    FormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ComponentFormData } from '../component-form-data';
import { Review } from 'app/modules/aboutme/aboutme-reviews/aboutme-reviews.component';
import { TranslocoModule } from '@ngneat/transloco';

export interface ReviewsData extends ComponentFormData {
    title: string;
    reviews: Review[];
}

@Component({
    selector: 'reviews-form',
    templateUrl: './reviews-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class ReviewsFormComponent {
    reviewForm: FormGroup;

    @Input() data: ReviewsData;
    @Output() dataChange = new EventEmitter<ReviewsData>();
    @Output() cancel = new EventEmitter<void>();

    constructor(private fb: FormBuilder) {
        this.reviewForm = this.fb.group({
            title: ['', Validators.required],
            reviews: this.fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            this.reviewForm.patchValue({
                title: this.data.title,
            });
            if (this.data.reviews) {
                this.data.reviews.forEach((review, index) => {
                    this.reviews.push(
                        this.fb.group({
                            name: [review.name, Validators.required],
                            text: [review.text, Validators.required],
                            stars: [
                                review.stars,
                                [
                                    Validators.required,
                                    Validators.min(1),
                                    Validators.max(5),
                                ],
                            ],
                            position: [index],
                        }),
                    );
                });
            }
        }
    }

    get reviews(): FormArray {
        return this.reviewForm.get('reviews') as FormArray;
    }

    addReview() {
        const reviewGroup = this.fb.group({
            name: ['', Validators.required],
            text: ['', Validators.required],
            stars: [
                0,
                [Validators.required, Validators.min(1), Validators.max(5)],
            ],
            position: [this.reviews.length],
        });
        this.reviews.push(reviewGroup);
    }

    removeReview(index: number) {
        this.reviews.removeAt(index);
    }

    onSubmit() {
        if (this.reviewForm.valid) {
            this.dataChange.emit(this.reviewForm.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
