<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar"
        />
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"
        ></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{ 'mr-px mb-px': !showAvatar || !user.avatar }"
        ></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
