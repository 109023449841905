import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { AboutMe } from 'app/core/entities/Aboutme';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ComponentData } from '../aboutme/aboutme.component';
import {
    AboutMeComponent,
    getAvailableComponents,
} from './availableComponents';
import { UpdateAboutMeDto } from './dto/updateAboutMe.dto';
import { ContactFormDto } from './dto/contactform.dto';
import { ComponentPositions } from './dto/updateComponentPositions.dto';
import { CreateAboutMeDto } from './dto/createAboutMe.dto';
import { TranslocoService } from '@ngneat/transloco';
import { RemainingSpaceDto } from './dto/remainingSpace.dto';

@Injectable({
    providedIn: 'root',
})
export class AboutMeService {
    private readonly BASE_URL: string = environment.apiUrl;
    private availableComponents: AboutMeComponent[] = [];
    private _aboutMe: BehaviorSubject<AboutMe | null> = new BehaviorSubject(
        null,
    );
    remainingSpaceUpdated = new EventEmitter<void>();

    mapBackendComponentTypeToAvailableType(
        backendType: string,
    ): string | undefined {
        const typeMapping: { [key: string]: string } = {
            audio: 'audios',
            events: 'events',
            formattedText: 'formattedTexts',
            imageGrid: 'imageGrids',
            location: 'locations',
            profile: 'profiles',
            reviews: 'reviews',
            techChart: 'techCharts',
            timeline: 'timelines',
            video: 'videos',
        };
        return typeMapping[backendType];
    }

    constructor(
        private _httpClient: HttpClient,
        private translocoService: TranslocoService,
    ) {
        this.availableComponents = getAvailableComponents(
            this.translocoService,
        );
    }

    /**
     * Getter for aboutMe
     */
    get aboutMe$(): Observable<AboutMe> {
        return this._aboutMe.asObservable();
    }

    getAboutMe(aboutmeId: number): Observable<AboutMe> {
        return this._httpClient
            .get<AboutMe>(`${this.BASE_URL}/aboutme/id/${aboutmeId}`)
            .pipe(
                tap((response: AboutMe) => {
                    this._aboutMe.next(response);
                }),
                catchError((error) => {
                    console.error('Error fetching AboutMe:', error);
                    return throwError(
                        () => new Error('Failed to fetch AboutMe'),
                    );
                }),
            );
    }

    getAboutMeBySubdomain(
        domain: string,
        subdomain: string,
    ): Observable<AboutMe> {
        return this._httpClient
            .get<AboutMe>(
                `${this.BASE_URL}/aboutme/subdomain/${domain}/${subdomain}`,
            )
            .pipe(
                tap((response: AboutMe) => {
                    this._aboutMe.next(response);
                }),
                catchError((error) => {
                    console.error('Error fetching AboutMe:', error);
                    return throwError(
                        () => new Error('Failed to fetch AboutMe'),
                    );
                }),
            );
    }

    createAboutMe(createAboutMeDto: CreateAboutMeDto): Observable<AboutMe> {
        return this._httpClient.post<AboutMe>(
            `${this.BASE_URL}/aboutme`,
            createAboutMeDto,
        );
    }

    updateAboutMe(
        aboutmeId: number,
        updateAboutMeDto: UpdateAboutMeDto,
    ): Observable<AboutMe> {
        return this._httpClient.put<AboutMe>(
            `${this.BASE_URL}/aboutme/${aboutmeId}`,
            updateAboutMeDto,
        );
    }

    /**
     * Map the backend aboutMe to the available component data.
     * Backend AboutMe has socialLinks, audios, profiles, etc.
     * ComponentData is an array with all mixed component types.
     * @param aboutMe
     */
    aboutMeToComponentData(aboutMe: AboutMe): ComponentData[] {
        const componentData: ComponentData[] = [];
        this.availableComponents.forEach((component) => {
            const backendType = this.mapBackendComponentTypeToAvailableType(
                component.type,
            );
            if (
                backendType &&
                aboutMe[backendType] &&
                aboutMe[backendType].length > 0
            ) {
                aboutMe[backendType].forEach((backendComponent) => {
                    componentData.push({
                        id: backendComponent.id,
                        type: component.type,
                        data: backendComponent,
                    });
                });
            }
        });
        componentData.sort((a, b) => a.data.position - b.data.position);
        return componentData;
    }

    createComponentData(
        aboutmeId: number,
        componentData: ComponentData,
    ): Observable<ComponentData> {
        return this._httpClient.post<ComponentData>(
            `${this.BASE_URL}/aboutme-component/${aboutmeId}/${componentData.type}`,
            componentData.data,
        );
    }

    updateComponentData(
        aboutmeId: number,
        componentData: ComponentData,
    ): Observable<ComponentData> {
        return this._httpClient.put<ComponentData>(
            `${this.BASE_URL}/aboutme-component/${aboutmeId}/${componentData.type}/${componentData.id}`,
            componentData.data,
        );
    }

    updateComponentPositions(
        aboutmeId: number,
        componentData: ComponentData[],
    ): Observable<void> {
        const componentPositionsDto: ComponentPositions = componentData.map(
            (component) => ({
                entity: component.type,
                componentId: component.id,
                position: component.data.position,
            }),
        );
        return this._httpClient.put<void>(
            `${this.BASE_URL}/aboutme-component/${aboutmeId}/update-positions`,
            componentPositionsDto,
        );
    }

    deleteComponentData(
        aboutmeId: number,
        componentData: ComponentData,
    ): Observable<ComponentData> {
        return this._httpClient.delete<ComponentData>(
            `${this.BASE_URL}/aboutme-component/${aboutmeId}/${componentData.type}/${componentData.id}`,
        );
    }

    sendContactForm(
        aboutmeId: number,
        contactForm: ContactFormDto,
    ): Observable<ContactFormDto> {
        return this._httpClient.post<ContactFormDto>(
            `${this.BASE_URL}/aboutme/${aboutmeId}/contact`,
            contactForm,
        );
    }

    uploadFile(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this._httpClient.post<any>(
            `${this.BASE_URL}/files/upload/`,
            formData,
            {
                headers: new HttpHeaders({
                    enctype: 'multipart/form-data',
                }),
            },
        );
    }

    /**
     * Fetch the remaining space available for the user.
     */
    getRemainingSpace(): Observable<RemainingSpaceDto> {
        return this._httpClient
            .get<RemainingSpaceDto>(`${this.BASE_URL}/files/remaining-space`)
            .pipe(
                catchError((error) => {
                    console.error('Error fetching remaining space:', error);
                    return throwError(
                        () => new Error('Failed to fetch remaining space'),
                    );
                }),
            );
    }

    notifyRemainingSpaceUpdate(): void {
        this.remainingSpaceUpdated.emit();
    }
}
