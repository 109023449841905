import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ElementRef,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import * as L from 'leaflet';
import { debounceTime } from 'rxjs/operators';
import { ComponentFormData } from '../component-form-data';
import { TranslocoModule } from '@ngneat/transloco';

export interface LocationData extends ComponentFormData {
    title: string;
    lat: number;
    lng: number;
    markerTitle: string;
}

@Component({
    selector: 'app-location-form',
    templateUrl: './location-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatCheckboxModule,
        TranslocoModule,
    ],
})
export class LocationFormComponent implements OnInit {
    @Input() set data(value: LocationData) {
        this._data = value;
        if (this.form) {
            this.form.patchValue(value);
            this.initMap();
        }
    }
    get data(): LocationData {
        return this._data;
    }
    private _data: LocationData = {
        title: '',
        lat: 0,
        lng: 0,
        markerTitle: '',
    };
    currentMarker: L.Marker | null = null;

    @Output() dataChange = new EventEmitter<LocationData>();
    @Output() cancel = new EventEmitter<void>();

    form!: FormGroup;

    @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;
    useCoordinates = false;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            title: [
                this.data.title,
                [Validators.required, Validators.minLength(1)],
            ],
            lat: [
                this.data.lat || 40.4168, // Default latitude for Madrid
                [Validators.required, Validators.pattern(/^-?\d+(\.\d+)?$/)],
            ],
            lng: [
                this.data.lng || -3.7038, // Default longitude for Madrid
                [Validators.required, Validators.pattern(/^-?\d+(\.\d+)?$/)],
            ],
            markerTitle: [
                this.data.markerTitle || 'Default Marker',
                [Validators.required, Validators.minLength(1)],
            ],
            searchLocation: [''],
            useCoordinates: [false],
        });

        // Set the path for Leaflet's images
        L.Icon.Default.imagePath = 'assets/icons/leaflet/';

        // Initialize the map with default or provided data
        this.initMap();

        // Subscribe to changes in lat and lng with debounce
        this.form.controls['lat'].valueChanges
            .pipe(debounceTime(300))
            .subscribe(() => this.updateMap());

        this.form.controls['lng'].valueChanges
            .pipe(debounceTime(300))
            .subscribe(() => this.updateMap());
    }

    map: any;
    zoom = 16;

    initMap() {
        this.map = L.map(this.mapContainer.nativeElement).setView(
            [this.form.controls['lat'].value, this.form.controls['lng'].value],
            this.zoom,
        );
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap',
        }).addTo(this.map);

        this.map.setView(
            [this.form.controls['lat'].value, this.form.controls['lng'].value],
            this.zoom,
        );
        this.currentMarker = L.marker([
            this.form.controls['lat'].value,
            this.form.controls['lng'].value,
        ])
            .addTo(this.map)
            .bindPopup(this.form.controls['markerTitle'].value)
            .openPopup();
    }

    onSubmit(): void {
        if (this.form.valid) {
            const { searchLocation, lat, lng, markerTitle, ...rest } =
                this.form.value;
            const formData = {
                ...rest,
                lat: parseFloat(lat),
                lng: parseFloat(lng),
                markerTitle: markerTitle,
            };
            this.dataChange.emit(formData);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    findLocation(searchQuery: string): Promise<void> {
        return new Promise((resolve) => {
            const formattedQuery = encodeURIComponent(searchQuery).replace(
                /%20/g,
                '+',
            );
            const url = `https://nominatim.openstreetmap.org/search?format=json&q=${formattedQuery}`;
            this.http.get(url).subscribe((res: any) => {
                if (res.length) {
                    this.form.controls['lat'].setValue(res[0].lat);
                    this.form.controls['lng'].setValue(res[0].lon);
                    if (this.map.hasLayer(this.currentMarker)) {
                        this.map.removeLayer(this.currentMarker);
                    }
                    this.map.setView(
                        [
                            this.form.controls['lat'].value,
                            this.form.controls['lng'].value,
                        ],
                        this.zoom,
                    );
                    this.currentMarker = L.marker([
                        this.form.controls['lat'].value,
                        this.form.controls['lng'].value,
                    ])
                        .addTo(this.map)
                        .bindPopup(this.form.controls['markerTitle'].value)
                        .openPopup();
                } else {
                    this.snackBar.open(
                        `No location with the name "${searchQuery}" was found`,
                        'Close',
                        {
                            duration: 3000,
                        },
                    );
                }
                resolve();
            });
        });
    }

    updateMap() {
        if (this.map && this.currentMarker) {
            const lat = this.form.controls['lat'].value;
            const lng = this.form.controls['lng'].value;
            this.map.setView([lat, lng], this.zoom);
            this.currentMarker.setLatLng([lat, lng]);
            this.currentMarker
                .bindPopup(this.form.controls['markerTitle'].value)
                .openPopup();
        }
    }
}
