import { Injectable } from '@angular/core';
import { getDomain, getSubdomain } from 'app/app.routing';

@Injectable({
    providedIn: 'root',
})
export class SubdomainService {
    getDomain(): string {
        return getDomain();
    }
    getSubdomain(): string | null {
        return getSubdomain();
    }
}
